import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { formatRichText } from '../helpers/contentful'
import { Heading2CSS, MainBodyCSS, MetaBodyCSS } from '../styles/typography'
import { breakpoints } from '../styles/breakpoints'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Wrapper from '../components/wrapper/wrapper'

const Content = styled.div`
  margin: auto;
  max-width: 600px;
`

const H2 = styled.h2`
  ${Heading2CSS}
  text-align: center;
`

const H3 = styled.h3`
  ${Heading2CSS}
  text-align: center;
`

const Block = styled.div`
  margin-bottom: 48px;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${breakpoints.laptop} {
    margin-bottom: 96px;
  }
`

const TitleContainer = styled.div`
  margin-bottom: 24px;

  @media ${breakpoints.laptop} {
    margin-bottom: 48px;
  }
`

const Item = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${breakpoints.laptop} {
    margin-bottom: 48px;
  }
`

const ItemBody = styled.p`
  ${MainBodyCSS}
  margin-bottom: 16px;
  text-align: center;
`

const ItemMeta = styled.div`
  p {
    ${MetaBodyCSS}
    color: #808080;
    text-align: center;
  }

  a {
    color: #808080;
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: #aaaaaa;
    }
  }
`

const PageTitle = data => {
  return <H2>{data.title}</H2>
}

const SecondaryTitle = data => {
  return <H3>{data.title}</H3>
}

export default function Contact({ data }) {
  const contact = data.allContentfulContact.edges[0].node

  return (
    <Layout>
      <SEO title="Contact" description="Contact" />

      <Wrapper>
        <Content>
          {contact.contentBlock.map((block, i) => {
            return (
              <Block key={i}>
                <TitleContainer>
                  {i === 0
                    ? <PageTitle title={block.title} />
                    : <SecondaryTitle title={block.title} />
                  }
                </TitleContainer>

                {block.textBlockItems.map((item, i) => {
                  return (
                    <Item key={i}>
                      <ItemBody>{item.title}</ItemBody>
                      <ItemMeta>
                        {formatRichText(item.childContentfulTextBlockBodyRichTextNode.json)}
                      </ItemMeta>
                    </Item>
                  )
                })}
              </Block>
            )
          })}
        </Content>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulContact {
      edges {
        node {
          contentBlock {
            title
            textBlockItems {
              title
              childContentfulTextBlockBodyRichTextNode {
                json
              }
            }
          }
        }
      }
    }
  }
`